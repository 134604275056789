
.modal{

}
.body{
  display: block;
  position: relative;
}

.text {
  position: relative;
  /* z-index: 1; */
  cursor: default;
}

.svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* z-index: 10; */
}
