
.page {
  width: 100%;
  height: 100%;
   background: rgb(50, 50, 50);
  /* background: #2c3e50; */
  /* padding: 30px; */
}

.sceneContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* cursor: none;  */
}

.hidePointer {
  /* For development, comment out. */
  /* cursor: none;  */
}

.showPointer {
  cursor: default;
}

.scene {
  /* border: var(--stroke-width) solid var(--text-color); */
}
